<template>
  <div>
    <CRow>
      <CCol sm="12">
        <CButton @click="print()" color="info" style="width: 9rem" class="float-right">
          <CIcon name="cil-print" />ພິມລາຍງານ
        </CButton>
      </CCol>
      <CCol sm="12">
        <hr />
      </CCol>
      <CCol sm="12">
        <div id="print-servicetotal-view">
          <CRow>
            <CCol sm="8"></CCol>
            <CCol sm="3">
              <CImg src="images/3c_link_logo.png" width="100%" />
            </CCol>
            <CCol sm="1"></CCol>
            <CCol sm="12">
              <br />
            </CCol>
            <CCol sm="12" class="text-center">
              <h5>
                <u>
                  <b>ລາຍງານການບໍລິການ</b>
                </u>
              </h5>
            </CCol>
            <CCol sm="12">
              <br />
            </CCol>
            <CCol sm="12">
              <CRow>
                <CCol sm="1"></CCol>
                <CCol>
                  <CRow>
                    <CCol sm="12" class="position-relative table-responsive">
                      <table class="table table-bordered">
                        <thead>
                          <tr>
                            <th class="text-center">
                              <strong>ລດ</strong>
                            </th>
                            <th class="text-center">
                              <strong>ລາຍການບໍລິການ</strong>
                            </th>
                            <th class="text-center">
                              <strong>ລາຄາ</strong>
                            </th>
                            <th class="text-center">
                              <strong>ຫົວໜ່ວຍ</strong>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="row in service" :key="row.service_id">
                            <td class="text-center">{{row.service_id}}</td>
                            <td>{{row.service_name}}</td>
                            <td class="text-right">{{row.service_price | numFormat(',000.00') }}</td>
                            <td class="text-center">{{row.service_unit}}</td>
                          </tr>
                        </tbody>
                      </table>
                    </CCol>
                  </CRow>
                </CCol>
                <CCol sm="1"></CCol>
              </CRow>
            </CCol>
          </CRow>
        </div>
      </CCol>
    </CRow>
  </div>
</template>
<script>
export default {
  name: "ServiceTotal",
  props: {
    service: {
      type: Array,
      required: true,
    },
  },
  methods: {
    print() {
      this.$htmlToPaper("print-servicetotal-view");
    },
  },
};
</script>