<template>
  <div>
    <CRow>
      <CCol sm="12">
        <CButton @click="print()" color="info" style="width: 9rem" class="float-right">
          <CIcon name="cil-print" />ພິມລາຍງານ
        </CButton>
      </CCol>
      <CCol sm="12">
        <hr />
      </CCol>
      <CCol sm="12">
        <div id="print-servicebyid-view">
          <CRow>
            <CCol sm="8"></CCol>
            <CCol sm="3">
              <CImg src="images/3c_link_logo.png" width="100%" />
            </CCol>
            <CCol sm="1"></CCol>
            <CCol sm="12">
              <br />
            </CCol>
            <CCol sm="12" class="text-center">
              <h5>
                <u>
                  <b>ລາຍງານລາຍລະອຽດການບໍລິການ</b>
                </u>
              </h5>
            </CCol>
            <CCol sm="12">
              <br />
            </CCol>
            <CCol sm="12">
              <CRow>
                <CCol sm="1"></CCol>
                <CCol>
                  <CRow>
                    <CCol sm="12">
                      <h5>1. ຂໍ້ມູນການໃຫ້ບໍລິການ</h5>
                    </CCol>
                    <CCol sm="12">
                      <strong>- ລະຫັດ : {{svById.service_id}}</strong>
                    </CCol>
                    <CCol sm="12">
                      <strong>- ຊື່ບໍລິການ : {{svById.service_name}}</strong>
                    </CCol>
                    <CCol sm="12">
                      <strong
                        v-if="svById.service_month == '0'"
                      >- ຮູບແບບບໍລິການ : ເກັບບໍລິການລາຍເດືອນ</strong>
                      <strong
                        v-if="svById.service_month == '1'"
                      >- ຮູບແບບບໍລິການ : ເກັບບໍລິການຄັ້ງດຽວ</strong>
                    </CCol>
                    <CCol sm="12">
                      <strong>- ຄ່າບໍລິການ : {{svById.service_price | numFormat(',000.00')}} ກີບ / {{svById.service_unit}}</strong>
                    </CCol>
                    <CCol sm="12">
                      <strong>- ລາຍລະອຽດຂອງການໃຫ້ບໍລິການ : {{svById.service_detail}}</strong>
                    </CCol>
                  </CRow>
                </CCol>
                <CCol sm="1"></CCol>
              </CRow>
            </CCol>
          </CRow>
        </div>
      </CCol>
    </CRow>
  </div>
</template>
<script>
export default {
  name: "ServiceById",
  props: {
    svById: {
      type: Object,
      required: true,
    },
  },
  methods: {
    print() {
      this.$htmlToPaper("print-servicebyid-view");
    },
  },
};
</script>