<template>
  <div v-if="userGroup == 'administrator'">
    <CCard>
      <CCardHeader>
        <strong>ລາຍງານການບໍລິການ</strong>
      </CCardHeader>
      <CCardBody>
        <div style="min-height: 55vh">
          <ValidationObserver v-slot="{ invalid }">
            <CForm ref="form" :model="form" @submit.prevent="handleServiceReport">
              <CRow>
                <CCol sm="4"></CCol>
                <CCol sm="4">
                  <CRow>
                    <CCol sm="12">
                      <validation-provider rules="required">
                        <div class="form-group">
                          <label>ເລືອກ :</label>
                          <v-select
                            prop="select"
                            v-model="form.select"
                            :options="selectList"
                            :reduce="code => code.code"
                            label="message"
                          />
                        </div>
                      </validation-provider>
                    </CCol>
                    <CCol sm="12" v-if="form.select == '1'">
                      <div class="form-group">
                        <label>ເລືອກຮອບແບບບໍລິການ :</label>
                        <v-select
                          prop="selectSv"
                          v-model="form.selectSv"
                          :options="selectSvList"
                          :reduce="code => code.code"
                          label="message"
                        />
                      </div>
                    </CCol>
                    <CCol sm="12" v-if="form.select == '2'">
                      <CInput label="ລະຫັດບໍລິການ :" prop="service_id" v-model="form.service_id" />
                    </CCol>
                    <CCol sm="12">
                      <CButton
                        type="submit"
                        :disabled="invalid || loading"
                        color="info"
                        class="float-right my-2"
                        style="width: 8rem"
                      >
                        <span v-if="!loading">
                          <CIcon name="cil-check" />ສະແດງຂໍ້ມູນ
                        </span>
                        <CSpinner
                          v-if="loading"
                          color="light"
                          style="width: 1.3rem; height: 1.3rem;"
                        />
                      </CButton>
                    </CCol>
                  </CRow>
                </CCol>
                <CCol sm="4"></CCol>
              </CRow>
            </CForm>
          </ValidationObserver>
        </div>
      </CCardBody>
    </CCard>
    <CModal
      title="ລາຍງານບໍລິການທັງໝົດ"
      size="lg"
      :show.sync="modalServiceTotal"
      :closeOnBackdrop="false"
    >
      <serviceTotal :service="service" />
      <template #footer>
        <div></div>
      </template>
    </CModal>
    <CModal
      title="ລາຍງານບໍລິການຕາມລະຫັດ"
      size="lg"
      :show.sync="modalServiceById"
      :closeOnBackdrop="false"
    >
      <serviceById :svById="svById" />
      <template #footer>
        <div></div>
      </template>
    </CModal>
    <CModal
      title="ບໍລິການທີ່ລູກຄ້າໃຊ້ງານ"
      size="lg"
      :show.sync="modalServiceCus"
      :closeOnBackdrop="false"
    >
      <serviceCus :serviceCus="serviceCus" />
      <template #footer>
        <div></div>
      </template>
    </CModal>
  </div>
</template>
<script>
import {
  getServiceByMonthAPI,
  getServiceByIdAPI,
  getServiceCusAPI,
} from "@/api/service";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { decrypt } from "@/libs/crypto";
import serviceTotal from "./components/ServiceTotal";
import serviceById from "./components/ServiceById";
import serviceCus from "./components/ServiceCus";
export default {
  name: "ServiceReport",
  components: {
    ValidationObserver,
    ValidationProvider,
    serviceTotal,
    serviceById,
    serviceCus,
  },
  data() {
    return {
      userGroup: decrypt(this.$store.getters.getUserGroup),
      form: {
        select: "",
        selectSv: "",
        service_id: "",
      },
      selectList: [
        { code: "1", message: "ຂໍ້ມູນບໍລິການທັງຫມົດ" },
        { code: "2", message: "ຂໍ້ມູນບໍລິການຕາມລະຫັດ" },
        { code: "3", message: "ຈໍານວນບໍລິການທີ່ລູກຄ້າໃຊ້ງານ" },
      ],
      selectSvList: [
        { code: "0", message: "ເກັບບໍລິການລາຍເດືອນ" },
        { code: "1", message: "ເກັບບໍລິການຄັ້ງດຽວ" },
      ],
      modalServiceTotal: false,
      modalServiceById: false,
      modalServiceCus: false,
      loading: false,
      service: [],
      svById: {},
      serviceCus: [],
    };
  },
  methods: {
    handleServiceReport() {
      this.loading = true;

      if (this.form.select == "1") {
        getServiceByMonthAPI(this.form.selectSv)
          .then((response) => {
            this.modalServiceTotal = true;
            this.service = response.data.data;
            this.loading = false;
          })
          .catch((error) => {
            this.$awn.alert(error.response.data.message);
            this.loading = false;
          });
      } else if (this.form.select == "2") {
        getServiceByIdAPI(this.form.service_id)
          .then((response) => {
            this.modalServiceById = true;
            this.svById = response.data.data;
            this.loading = false;
          })
          .catch((error) => {
            this.$awn.alert(error.response.data.message);
            this.loading = false;
          });
      } else if (this.form.select == "3") {
        getServiceCusAPI()
          .then((response) => {
            this.modalServiceCus = true;
            this.serviceCus = response.data.data;
            this.loading = false;
          })
          .catch((error) => {
            this.$awn.alert(error.response.data.message);
            this.loading = false;
          });
      }
    },
  },
};
</script>